import React, { useState } from "react"
import styled, { withTheme } from "styled-components"
import { StyledWrapper } from "./wrapper.jsx"
import Slider from "./slider.jsx"
import SignupButton from "./signup-button"

const Header = styled.header`
  height: 596px;
  padding-top: 80px;
  background-color: ${props => props.theme.colors.tuftsBlue};
  position: relative;
  overflow: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: white;

  @media screen and (max-width: 1024px) {
    height: inherit;
    padding: 80px 0 48px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 160px;
    content: "";
    background: linear-gradient(
      0deg,
      rgb(14, 21, 39) 0%,
      rgba(70, 100, 181, 0) 100%
    );
    opacity: 0.25;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
`

const Wrapper = styled(StyledWrapper)``

const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 100;
  padding: 42px 0 64px 0;
  text-align: center;

  @media screen and (min-width: 1024px) {
    font-size: 36px;
  }
`

const SubTitle = styled.sub`
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.5;
`

export const BannerContent = styled.div`
  display: flex;
  width: 100;
  justify-content: space-between;
`

export const BannerContentLeft = styled.div`
  width: 100%;
  text-align: center;

  @media screen and (min-width: 1024px) {
    width: inherit;
    text-align: left;
    padding-right: 24px;
  }
`

const Paragraph = styled.p`
  font-size: 22px;
  line-height: 40px;
  margin: 8px 0 40px 0;

  /* font-size: 24px; */
  @media screen and (min-width: 1024px) {
    /* width: 460px; */
  }

  @media screen and (min-width: 1200px) {
    width: 460px;
  }
`

export default withTheme(props => {
  const [position, setPosition] = useState(0)

  const onDrag = (_event, { node }) =>
    setPosition(node.getBoundingClientRect().left + node.offsetWidth / 2)

  return (
    <Header
      style={{
        background: `${props.theme.colors.tuftsBlue} linear-gradient(90deg, rgba(0, 0, 0, 0.15) ${position}px, ${props.theme.colors.tuftsBlue} 0)`,
      }}
    >
      <Wrapper>
        <Title>Web Services para el SAT</Title>
        <BannerContent>
          <BannerContentLeft>
            <SubTitle>Para desarrolladores y empresas</SubTitle>
            <Paragraph>
              APIs potentes que te permiten conectar tus sistemas con toda la
              información del SAT
            </Paragraph>
            <SignupButton large />
          </BannerContentLeft>
          <Slider onDrag={onDrag} />
        </BannerContent>
      </Wrapper>
    </Header>
  )
})
