import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import Draggable from "react-draggable"

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  width: 700px;
  min-width: 700px;
  height: 380px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const Bar = styled.div`
  position: relative;
  height: 420px;
  width: 4px;
  top: -40px;
  left: 50%;
  background-color: #f45c69;
  border-radius: 2px 2px 0 0;
`

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(244, 92, 105, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(244, 92, 105, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(244, 92, 105, 0);
  }
`

const Button = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 100%;
  border: 4px solid ${props => props.theme.colors.fieryRose};
  box-sizing: content-box;
  cursor: ew-resize;
  animation: ${pulse} 2s infinite;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f45c69;
    border-radius: 100%;
  }
`

const LeftWrapper = styled.div`
  float: left;
  width: 50%;
  overflow: hidden;
`

const RightWrapper = styled.div`
  float: right;
  width: 50%;
  overflow: hidden;
  direction: rtl;
`

const Slider = props => {
  const [position, setPosition] = useState()
  const barRef = useRef()

  const onDrag = (event, element) => {
    setPosition(element.x)
    props.onDrag(event, element)
  }

  const triggerDrag = () => {
    onDrag(null, { x: position, node: barRef.current })
  }

  useEffect(() => {
    window.addEventListener("resize", triggerDrag)

    return () => window.removeEventListener("resize", triggerDrag)
  }, [])

  useEffect(triggerDrag, [])

  return (
    <SliderWrapper>
      <LeftWrapper style={{ width: `calc(50% + ${position}px)` }}>
        <img src="/images/header_slider_api.png" width={700} draggable={false} />
      </LeftWrapper>
      <RightWrapper style={{ width: `calc(50% - ${position}px)` }}>
        <img src="/images/header_slider_web.png" width={700} draggable={false} />
      </RightWrapper>
      <Draggable
        axis="x"
        bounds="parent"
        handle=".handle"
        onDrag={onDrag}
        onStop={triggerDrag}
      >
        <Bar ref={barRef}>
          <Button className="handle" />
        </Bar>
      </Draggable>
    </SliderWrapper>
  )
}

Slider.propTypes = {
  onDrag: PropTypes.func,
}

Slider.defaultProps = {
  onDrag: () => {},
}

export default Slider
